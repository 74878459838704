import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Parser from 'html-react-parser';
import $ from 'jquery';
import axios from 'axios'; 
import Notification from './Notification';

import 'swiper/css';
import 'swiper/css/pagination';

import './Product.css';


const Product = props => {
  const [imagini1, setImagini]=useState([props.imfirst]);
  const [produs, setProdus]=useState([]);
  const [slides, setSlides]=useState([]);
  const [selectedP, setSelectedP]=useState(0);
  const [selHtml, setSelHtml]=useState();
  const [marimi, setMarimi]=useState([]);
  const [colectie, setColectie]=useState([]);
  const [isLoaded, setIsLoaded]=useState(props.loadp);
  
  //let slides=[];

  const selectMarime = (product_id) =>{
    console.log(product_id);
    setSelectedP(product_id);
    $('#alegemar').hide();
    
  }
  

  const doColectie = async (e,product_id) =>{
    e.preventDefault();
    console.log(product_id);
    await fetch('/products.php?product_id='+product_id) 
                      .then(res => res.json())
                      .then(
                        (result) => {
                          
                          console.log(result);
                          
                         
                          let slides2=[];
                          setImagini([]);
                          setSlides([]);
                          setProdus(result);
                          setImagini(result.poze);
                          setMarimi(result.marimi);
                          setColectie(result.colectie);
                          let imagini=result.poze;
                          if(imagini){
                        
                            console.log(imagini);
                              imagini.forEach((poza) => {  console.log(poza);
                                slides2.push(
                                  <SwiperSlide key={poza.pair_id}>
                                    <img src={poza.poza} alt=""/>
                                  </SwiperSlide>
                                )
                                });
                            }
                            setSlides(slides2);
                            setIsLoaded(true);
                         
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                          console.log(error)
                        }
                      )
  }
  const doSubmit = (e,product_id, price, product_code) =>{
    e.preventDefault();
    console.log(product_id);
    setSelectedP(product_id);
    var form = $('.adaugaincos');
    console.log(form);
    var actionUrl = form.attr('action');

    var bodyFormData = new FormData();
    bodyFormData.append('result_ids', 'cart_status*,wish_list*,checkout*,account_info*');
    bodyFormData.append('redirect_url', 'index.php?sl=ro&dispatch=products.view&product_id='+product_id);
    bodyFormData.append('product_data['+product_id+'][product_id]', product_id);
    bodyFormData.append('product_data['+product_id+'][price]', price);
    bodyFormData.append('product_data['+product_id+'][product_code]', product_code);
    bodyFormData.append('product_data['+product_id+'][amount]', 1);
    bodyFormData.append('dispatch[checkout.add..'+product_id+']','');
    bodyFormData.append('full_render','Y');
    bodyFormData.append('is_ajax', '1');

   
   // $.ceAjax('submitForm', form, $('#button_cart_'+product_id));
   axios({
        method: 'post',
        url: '/',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (response) {
        //handle success
        //console.log(response);
        let htmlss=response.data.html;
        if(htmlss){
          //console.log(htmlss);
            Object.keys(htmlss).map(function(key) {
              let htm=htmlss[key];
              console.log(key);
              $('#'+key).html(htm);
              
            })
        }
        let addcart=response.data.notifications;
        if(addcart){
   
          
            console.log(addcart);
            Object.keys(addcart).map(function(key) {
              let notf=addcart[key];
              console.log(notf.message);
              if(notf.type=='I'){
                  //setSelHtml(notf.message);

                
                  $('body').append(
                      '<div class="ui-widget-overlay" style="z-index:1010" data-ca-notification-key="' + key + '"></div>'
                  );

                  var notification = $('<div class="cm-notification-content cm-notification-content-extended notification-content-extended ' + (notf.message_state == "I" ? ' cm-auto-hide' : '') + '" data-ca-notification-key="' + key + '">' +
                      '<h1>' + notf.title + '<span class="cm-notification-close close"></span></h1>' +
                      '<div class="notification-body-extended">' +
                      notf.message +
                      '</div>' +
                      '</div>');

                  var notificationMaxHeight = window.innerHeight - 300;

                  $(notification).find('.cm-notification-max-height').css({
                      'max-height': notificationMaxHeight
                  });

                  // FIXME I-type notifications are embedded directly into the body and not into a container, because a container has low z-index and get overlapped by modal dialogs.
                  //container.append(notification);
                  $('body').append(notification);
                  notification.css('top', window.innerHeight / 2 - (notification.height() / 2));
            }

            })
            
          }
       
        
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    
  }
 

  useEffect(() => {
    console.log(props);
    let slides2=[];
    setImagini([]);
    setSlides([]);
    setProdus(props.product);
    setImagini(props.product.poze);
    setMarimi(props.product.marimi);
    setColectie(props.product.colectie);
    let imagini=props.product.poze;
    if(imagini){
   
      console.log(imagini);
        imagini.forEach((poza) => {  console.log(poza);
          slides2.push(
            <SwiperSlide key={poza.pair_id}>
              <img src={poza.poza} alt=""/>
            </SwiperSlide>
          )
          });
      }
      setSlides(slides2);
      setIsLoaded(true);
     
  }, [props]);
 
 
  //console.log(imagini);
  return (
    <>
    {isLoaded===false ?
      <div>
        loading
      </div>
      :
      <>
    {produs.price >0  && (
     <div className='spp'>
      <link href="/js/styleall.css?r=4324234324" rel="stylesheet" media="all"/>
      <link href="/js/checkout.css?r=4324234324" rel="stylesheet" media="all"/>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        slidesPerView="1.3"
        rebuildOnUpdate="true"
      >
       {slides}
        
      </Swiper>
        <div className="ty-product-block__left">
        <form action="/" method="post" name={`product_form_${selectedP}`} enctype="multipart/form-data" className="cm-disable-empty-files cm-ajax cm-ajax-full-render cm-ajax-status-middle adaugaincos">
            <input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*" />
            <input type="hidden" name="redirect_url" value={`index.php?dispatch=products.view&amp;product_id=${selectedP}`} />
            <input type="hidden" name={`product_data[${selectedP}][product_id]`} value={selectedP} />
            <input type="hidden" name="appearance[show_price_values]" value="1" />
            <input type="hidden" name="appearance[show_price]" value="1" />
            <input type="hidden" name={`product_data[${selectedP}][price]`} value={props.product.price} />
            <input type="hidden" name={`product_data[${selectedP}][product_code]`} value={props.product.product_code} />
            <input type="hidden" name={`product_data[${selectedP}][amount]`} value={1} />
            <input type="hidden" name="appearance[show_product_options]" value="1" />
            <input type="hidden" name="appearance[details_page]" value="1" />
            <input type="hidden" name="appearance[info_type]" value="D" />
            <input type="hidden" name={`dispatch[checkout.add..${selectedP}]`} />
            <input type="hidden" name="full_render" value="Y" />
            <input type="hidden" name="is_ajax" value="1" />

            <h1 className="ty-product-block-title"><bdi> {produs.product}</bdi></h1>

            <div className="brand"></div>

            <div className="prices-container price-wrap">
                <div className="ty-product-prices">
                    <div className="ty-product-block__price-actual">
                        <span className="cm-reload-70134 ty-price-update" id={`price_update_${selectedP}`}>
                            
                     

                            <span className="ty-price red" id="line_discounted_price_70134">
                                <bdi>
                                    <span id="sec_discounted_price_70134" className="ty-price-num">{produs?.price?.split('.')[0]}<sup>{produs?.price?.split('.')[1]}</sup></span>&nbsp;<span className="ty-price-num">lei</span>
                                </bdi>
                            </span>
                        </span>
                    </div>

                    <span className="cm-reload-70134" id="old_price_update_70134">
                        <span className="red ty-list-price ty-nowrap" id="line_list_price_70134">
                            <span className="list-price-label">Pret vechi:</span>
                            <span className="ty-strike">
                                <bdi>
                                    <span id="sec_list_price_70134" className="ty-list-price ty-nowrap">{produs?.list_price?.split('.')[0]}<sup>{produs?.list_price?.split('.')[1]}</sup></span>&nbsp;<span className="ty-list-price ty-nowrap">lei</span>
                                </bdi>
                            </span>
                        </span>
                    </span>
                    <div className="tooltip1">
                        <img src="http://zappatos.ro/img/info_prp.png" style={{width: "18px", marginLeft: "0px", marginTop: "-5px"}} />
                        <span className="tooltiptext">Acesta este Prețul Recomandat de Producător.</span>
                    </div>

                    
                </div>
            </div>
            
            <div className="colectie1">
                <div className="colectie2   swiper-container">
                 <div className="swiper-wrapper">
                 {colectie.map(col => { 
                      return (
                       
                        <div className="collll" style={{textAlign:'center', marginBottom: '15px'}}>
                                  <a data-id={col.product_id} style={{border: '1px solid #fff',margin: '5px 5px 5px 0', borderRadius: '50%',width: '66px',height: '66px', display: 'block', float: 'left',overflow: 'hidden', marginBottom:'3px'}} onClick={(e)=>doColectie(e,col.product_id)} href={`/index.php?dispatch=products.view&product_id=${col.product_id}`}>
                                    <img src={col.imagine} style={{width:'66px!important',marginTop: '-14px'}} />
                                  </a>
                                  {col.culoare}
                        </div>
                       
                      )
                    })}
                                 
                                
                                
                </div>
                 
                </div>
               
           </div>
            <div className="ty-product-block__option">
              
           

            {marimi.map(produs1 => { 
             return (
                <>
                  <input
                      type="radio"
                      name="feature_1"
                      data-ca-product-id={produs1.product_id}
                      className="hidden ty-product-options__radio cm-ajax cm-ajax-force"
                     
                  />

                  <label for="feature_1_variant_9_70134" className="ty-product-options__radio--label optlb_70138"  onClick={()=>selectMarime(produs1.product_id)}> <span className="ty-product-option-checkbox"></span>{produs1.marime}<span className="ty-product-option-checkbox"></span> </label>  
                  </>
             )
                
                
              })}
                
                
            </div>
            <div className="ty-product-block__button" style={{position:'relative'}}>
              <button disabled="" className='addis ty-btn__primary ty-btn__big  cm-form-dialog-closer ty-btn' type="submit" id="alegemar">Alege Mărimea</button>
              <button id={`button_cart_${selectedP}`} className="ty-btn__primary ty-btn__big ty-btn__add-to-cart cm-form-dialog-closer ty-btn" onClick={(e)=>doSubmit(e,selectedP)} type="submit" name={`dispatch[checkout.add..${selectedP}]`}>Comandă</button>
              <a className="ty-btn ty-btn__text ty-add-to-wish cm-submit text-button " id="button_wishlist_153407" data-ca-dispatch={`dispatch[wishlist.add...${selectedP}]`}></a>
            </div>
        </form>



              
        </div>
      </div>



    )}
    </> 
            }
          </> 
  );
};

export default Product;