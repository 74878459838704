import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { Router, Route, IndexRoute, browserHistory, Link } from 'react-router';
import './index.css';
import App from './App';
import MyComponent from './Lista';
import Lista from './Lista2';
import Product from './Produs';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

render(
      <Router
        history={browserHistory}>
             <Route
          path="/*"
          component={App}>
  
          <IndexRoute
            component={MyComponent} />
            
             
          
          </Route>
          <Route path="/photo" component={Product} />
      </Router>
   ,
  document.getElementById('root')
);
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
